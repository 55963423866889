@import url(https://fonts.googleapis.com/css?family=Poppins|Raleway&display=swap);
.title {
  font-family: 'Poppins', sans-serif;
  font-size: 2.5rem;
  font-weight: 800;
  color: #141c3a;
}

.header,
.header-invert {
  font-family: 'Poppins', sans-serif;
  font-size: 2rem;
  font-weight: 800;
  color: #141c3a;
}

.subtitle {
  font-family: 'Raleway', sans-serif;
  font-size: 1rem;
  color: #222222;
}

p {
  font-family: 'Raleway', sans-serif;
  line-height: 200%;
  font-size: 1rem;
}

.header-invert,
.paragraph-invert {
  color: white;
}

/*
==========
COMPONENTS
==========
*/
.card-caption-title {
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  font-weight: 800;
  color: white;
}

.button .button-text {
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
}

.button-large .button-text {
  font-family: 'Poppins', sans-serif;
  font-size: 1.25rem;
}

.timeline .panel-logo-text {
  font-family: 'Poppins', sans-serif;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 100%;
  color: white;
}

.timeline .panel-title {
  font-family: 'Poppins', sans-serif;
  font-size: 1.5rem;
  line-height: 100%;
  font-weight: 800;
}

.timeline .panel-company,
.timeline .panel-date,
.timeline .panel-description {
  font-family: 'Raleway', sans-serif;
  font-size: 1rem;
}

.timeline .panel-date {
  color: #222222;
}

@media only screen and (max-width: 700px) {
  .title {
    font-size: 2rem;
  }
  .subtitle {
    font-size: 1rem;
  }
  .header,
  .header-invert {
    font-size: 1.5rem;
  }
  /*
  ==========
  COMPONENTS
  ==========
  */
  .button .button-text {
    font-size: 0.9rem;
  }
  .button-large .button-text {
    font-size: 1rem;
  }
  .card-caption-title {
    font-size: 0.75rem;
  }
  .timeline .panel-title,
  .timeline .panel-logo-text {
    font-size: 1rem;
  }
  .timeline .panel-logo-text {
    font-size: 1rem;
  }
  .timeline .panel-company,
  .timeline .panel-date,
  .timeline .panel-description {
    font-size: 0.75rem;
  }
}

@media only screen and (max-width: 400px) {
  .timeline .panel-title {
    font-size: 0.9rem;
  }
}

* {
  margin: 0;
  padding: 0;
}

body {
  min-width: 300px;
}

/*
=============
SHARED STYLES
=============
*/
a {
  text-decoration: none;
}

.page-fill {
  min-height: 500px;
}

.page-accent {
  min-height: 500px;
  background-image: linear-gradient(45deg, #13cae2, #7328f0);
}

.page-box-wrapper {
  margin-bottom: -10rem;
}

.page-box {
  margin: auto;
  margin-top: -10rem;
  min-height: 500px;
  width: 80vw;
  min-width: 280px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  margin-bottom: 10rem;
}

.page-card {
  position: relative;
  margin: auto;
  width: 80vw;
  min-width: 280px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  background-image: linear-gradient(45deg, #13cae2, #7328f0);
}

#header #header-bar {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 10px;
}

#footer {
  margin-top: -10rem;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -ms-grid-rows: (65px)[3] auto 50px auto 75px;
      grid-template-rows: repeat(3, 65px) auto 50px auto 75px;
      grid-template-areas: '.' '.' '.' 'logo' '.' 'icons' '.';
  background-color: #141c3a;
}

#footer #icon-bar {
  -ms-grid-row: 6;
  -ms-grid-column: 1;
  grid-area: icons;
  margin-bottom: 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  align-self: center;
}

#footer #footer-logo {
  -ms-grid-row: 4;
  -ms-grid-column: 1;
  grid-area: logo;
  -ms-grid-column-align: center;
      justify-self: center;
}

.icon {
  height: 45px;
  width: 45px;
  margin-left: 25px;
  margin-right: 25px;
  padding: 2px;
  border-radius: 50%;
  background-image: linear-gradient(45deg, #13cae2, #7328f0);
}

.icon .icon-padding {
  display: -ms-grid;
  display: grid;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.icon .icon-svg {
  -ms-grid-column-align: center;
      justify-self: center;
  -ms-grid-row-align: center;
  -webkit-align-self: center;
          align-self: center;
  height: 24px;
  width: 24px;
}

.card {
  position: relative;
  border-radius: 20px;
}

.card .card-thumbnail,
.card .card-caption {
  position: absolute;
  border-radius: 20px;
  height: 100%;
  width: 100%;
  cursor: pointer;
  background-color: #141c3a;
}

.card .card-thumbnail {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.card .card-caption {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      grid-template-areas: 'title' 'title' 'button';
}

.card .card-caption-title {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: title;
  align-self: center;
      justify-self: center;
  padding: 10px;
  text-align: center;
}

.card .button {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  grid-area: button;
  align-self: flex-start;
      justify-self: center;
}

.card:focus {
  outline: none;
}

.button,
.button-large {
  display: -ms-grid;
  display: grid;
  background-image: linear-gradient(45deg, #13cae2, #7328f0);
}

.button .button-text,
.button-large .button-text {
  -ms-grid-row-align: center;
  -webkit-align-self: center;
          align-self: center;
  -ms-grid-column-align: center;
      justify-self: center;
  text-align: center;
}

.button {
  padding: 2px;
  border-radius: 20px;
}

.button .button-text {
  border-radius: 15px;
  padding: 0px 10px;
}

.button-large {
  padding: 3px;
  border-radius: 40px;
}

.button-large .button-text {
  border-radius: 35px;
  padding: 1.2rem;
}

.timeline {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  position: relative;
}

.timeline .timeline-panels * {
  z-index: 100;
}

.timeline .timeline-vertline-show {
  position: absolute;
  width: 10px;
}

.timeline .timeline-vertline {
  position: absolute;
  width: 10px;
  background-image: linear-gradient(0deg, #13cae2, #7328f0);
}

.timeline-panel {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 4fr 1fr 4fr;
      grid-template-columns: 4fr 1fr 4fr;
  grid-column-gap: 20px;
}

.timeline-panel .panel-section-left {
  text-align: right;
}

.timeline-panel .panel-section-left,
.timeline-panel .panel-section-right {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

.timeline-panel .panel-section-center {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.timeline-panel .panel-logo-text,
.timeline-panel .panel-logo-img {
  border-radius: 50%;
}

.timeline-panel .panel-logo-border-wrapper {
  position: absolute;
}

.timeline-panel .panel-logo-border-show,
.timeline-panel .panel-logo-border-hide {
  position: absolute;
}

.timeline-panel .panel-logo-border {
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 50%;
}

.timeline-panel .panel-logo-text {
  display: -webkit-flex;
  display: flex;
  text-align: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-image: linear-gradient(45deg, #13cae2, #7328f0);
}

.timeline-panel .panel-description {
  margin-top: 20px;
}

#title {
  height: 100vh;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      grid-template-areas: 'header' 'title' '.' '.' 'svg';
  padding: 0 10rem;
}

#title #header {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: header;
  -ms-grid-row-align: center;
  align-self: center;
}

#title #title-text-wrapper {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: title;
      justify-self: center;
  align-self: center;
  text-align: center;
}

#title #title-svg {
  -ms-grid-row: 5;
  -ms-grid-column: 1;
  grid-area: svg;
  -ms-grid-column-align: center;
      justify-self: center;
  -ms-grid-row-align: end;
  align-self: end;
  height: 300px;
}

#title .subtitle {
  margin-top: 20px;
}

.boy-waving .wave-arm {
  -webkit-transform-origin: 110px 160px;
          transform-origin: 110px 160px;
  -webkit-animation-name: boy-wave;
          animation-name: boy-wave;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
}

@-webkit-keyframes boy-wave {
  from {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  to {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
  }
}

@keyframes boy-wave {
  from {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  to {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
  }
}

@media only screen and (max-width: 700px) {
  #title {
    padding: 0 20px;
  }
}

@media only screen and (max-height: 600px) {
  #title #title-svg {
    height: 200px;
  }
}

#intro #intro-text {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -ms-grid-rows: 125px auto (75px)[3];
      grid-template-rows: 125px auto repeat(3, 75px);
      grid-template-areas: 'title' 'paragraph' '.' '.' '.';
  padding: 0 10rem;
  text-align: center;
}

#intro #intro-header {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: title;
  align-self: center;
      justify-self: center;
}

#intro #intro-paragraph {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  grid-area: paragraph;
  align-self: center;
      justify-self: center;
}

@media only screen and (max-width: 700px) {
  #intro #intro-text {
    padding: 0 20px;
  }
}

#projects #projects-box {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -ms-grid-rows: minmax(75px, 100px) (auto)[3] minmax(100px, 125px);
      grid-template-rows: minmax(75px, 100px) repeat(3, auto) minmax(100px, 125px);
      grid-template-areas: 'title' 'projects' 'projects' 'projects' 'button';
  background-color: white;
  padding: 5px;
}

#projects #projects-header {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: title;
  align-self: center;
      justify-self: center;
}

#projects #projects-container {
  -ms-grid-row: 2;
  -ms-grid-row-span: 3;
  -ms-grid-column: 1;
  grid-area: projects;
  justify-self: center;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

#projects #projects-container .card {
  min-width: 300px;
  max-width: 400px;
  width: 40%;
  margin: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}

#projects #projects-button {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  grid-area: button;
  align-self: center;
      justify-self: center;
}

@media only screen and (max-width: 700px) {
  #projects #projects-box {
    padding: 0px;
  }
  #projects #projects-container .card {
    min-width: 250px;
  }
}

#journey {
  height: auto;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -ms-grid-rows: minmax(75px, 100px) auto minmax(100px, 125px) 50px;
      grid-template-rows: minmax(75px, 100px) auto minmax(100px, 125px) 50px;
      grid-template-areas: 'title' 'timeline' 'button' '.';
  padding: 0 2.5rem;
}

#journey #journey-header {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: title;
  align-self: center;
      justify-self: center;
}

#journey #journey-timeline {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  grid-area: timeline;
  -ms-grid-column-align: center;
      justify-self: center;
}

#journey #journey-button {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  grid-area: button;
  align-self: center;
      justify-self: center;
}

@media only screen and (max-width: 700px) {
  #journey {
    padding: 0 5px;
  }
}

#learn #learn-text {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -ms-grid-rows: minmax(100px, 125px) auto minmax(100px, 125px);
      grid-template-rows: minmax(100px, 125px) auto minmax(100px, 125px);
      grid-template-areas: 'title' 'paragraph' 'button';
  padding: 0 10rem;
  text-align: center;
}

#learn #learn-header {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: title;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
      justify-self: center;
}

#learn #learn-paragraph {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  grid-area: paragraph;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
      justify-self: center;
}

#learn #learn-button {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  grid-area: button;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
      justify-self: center;
}

#learn .text-link {
  color: white;
  text-decoration: underline;
}

@media only screen and (max-width: 700px) {
  #learn #learn-text {
    padding: 0 20px;
  }
}
/*# sourceMappingURL=main.css.map */
